import React from 'react'
import 'assets/app.scss'
import { GallerySection } from 'components/GallerySection'

const IndexPage: React.FC = () => {
  return <div className='min-h-[100vh] w-full flex justify-center bg-slate-50 overflow-auto'>
    <div className="flex flex-col items-center justify-start w-full max-w-screen-xl font-mono">

      <div className="flex items-center w-full px-4 mt-5 md:px-8">
        <div>
          <div className="text-5xl text-cyan-600">SyxWorks</div>
          <div className="text-slate-400">Software developer-consultant</div>
        </div>

        <div className="flex-grow"></div>

        <div className="flex items-center mx-4">
          <div className="px-4 py-5 cursor-pointer group">
            <div className="text-cyan-600 transition-all after:content-[''] after:w-full block after:border-b-2 after:border-b-slate-300 after:absolute after:bottom-0 after:left-0 relative after:transform after:translate-y-5 group-hover:after:translate-y-2 after:opacity-0 group-hover:after:opacity-100 after:transition-all">
              About
            </div>
          </div>

          <div className="px-4 py-5 cursor-pointer group">
            <div className="text-cyan-600 transition-all after:content-[''] after:w-full block after:border-b-2 after:border-b-slate-300 after:absolute after:bottom-0 after:left-0 relative after:transform after:translate-y-5 group-hover:after:translate-y-2 after:opacity-0 group-hover:after:opacity-100 after:transition-all">
              Gallery
            </div>
          </div>

          <div className="px-4 py-5 cursor-pointer group">
            <div className="text-cyan-600 transition-all after:content-[''] after:w-full block after:border-b-2 after:border-b-slate-300 after:absolute after:bottom-0 after:left-0 relative after:transform after:translate-y-5 group-hover:after:translate-y-2 after:opacity-0 group-hover:after:opacity-100 after:transition-all">
              Technical
            </div>
          </div>

          <div className="px-4 py-5 cursor-pointer group">
            <a className="text-cyan-600 transition-all after:content-[''] after:w-full block after:border-b-2 after:border-b-slate-300 after:absolute after:bottom-0 after:left-0 relative after:transform after:translate-y-5 group-hover:after:translate-y-2 after:opacity-0 group-hover:after:opacity-100 after:transition-all"
              href='mailto:sayhao@syxworks.me'
            >
              Contact
            </a>
          </div>
        </div>
      </div>

      <div className="font-serif font-light text-8xl leading-[7rem] w-full px-4 md:px-8 pt-10">
        Hey,<br />I'm Sayhao.<br />
        <p className="mt-4 text-xl leading-9">I design and build websites, apps and other interesting things.</p>
      </div>

      <div className='h-[30vh]' />
      <GallerySection />
    </div>
  </div>
}

export default IndexPage
