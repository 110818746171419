import React from  'react'
import { SectionTitle } from './SectionTitle'
import { StaticImage } from 'gatsby-plugin-image'

export type PGallerySection = {
}
export const GallerySection: React.FC<PGallerySection> = ({ children }) => {
	return <>
		<SectionTitle>~ Gallery ~</SectionTitle>

		<div className='flex flex-col items-stretch justify-start'>
			<GalleryItemBlock
				link='https://ihaveadilemma.app'
				titleClassName='text-orange-500'
				className='hover:bg-orange-600/10 active:bg-orange-600/20'
				imgBlock={<StaticImage
					src='../images/ihad-preview.png'
					alt='"I Have A Dilemma" app screenshot'
					placeholder='blurred'
					layout='constrained'
					className='border rounded-md border-slate-300'
				/>}
				title='I Have A Dilemma'
				description={ <><p className='mb-3'>Are you facing a complicated choice? Like which phone to buy, or whether to do A or B or C this weekend?</p><p className='mb-3'>This might (really) just help you out.</p></>}
			/>

			<GalleryItemBlock
				link='https://outer-space.syxworks.me'
				className='hover:bg-slate-600/10 active:bg-slate-600/20'
				titleClassName='text-slate-700'
				imgBlock={ <StaticImage
					src='../images/syxly-outerspace-preview.jpg'
					alt='"Outer Space" app screenshot'
					placeholder='blurred'
					layout='constrained'
					className='rounded-md '
				/> }
				title='Outer Space.'
				description={ <>
					<p className='mb-3'>Gaze at the stars and feel for a moment our smallness<br />in this huge and magnificent universe.</p>
					<p className='mb-3'>Might be therapeutic.</p>
					<p className='mb-3'>Powered by NASA's Astronomy Photo of the Day (APOD).</p>
				</> }
			/>
		</div>
	</>
}

const GalleryItemBlock: React.FC<{imgBlock: React.ReactNode, title: React.ReactNode, description: React.ReactNode, link: string, titleClassName?: string, className?: string}> = ({ imgBlock, title, description, link, titleClassName, className }) => {
	return <a className={ `items-start w-full max-w-screen-md p-3 my-5 transition-colors rounded-md cursor-pointer ${ className ?? 'hover:bg-cyan-600/10 active:bg-cyan-600/20'}`} href={link} target='_blank' rel='noopener'>
		<div className='w-full min-w-0'>
			{ imgBlock }
		</div>
		<div className='h-3' />
		<div className='w-full min-w-0'>
			<div className={`mb-3 text-2xl font-bold text-center ${titleClassName ?? 'text-cyan-600'}`}>{ title }</div>
			<div className='text-center text-slate-800'>{ description }</div>
		</div>
	</a>
}
